import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { graphql, navigate } from 'gatsby'
import propTypes from 'prop-types'

import Layout from '../../layouts/default'
import Banner from '../../components/sections/_banner/default'

import Heading from '../../components/app-components/dashed-heading'
import LoginForm from '../../components/app-components/login-form'
import Icon from '../../components/app-components/icon'
import { doCheckLogin, doLogin } from '../../store/authentication/actions'

import './styles.scss'
import { getUser } from '../../store/authentication/selectors'

import BannerYellowRoundImg from '../../assets/images/booke-yellow-vertical-lines.png'

const SignInTemplate = ({
  login,
  user,
  checkLogin,
  location,
  data: {
    wpPage: { seo },
  },
}) => {
  useEffect(() => {
    async function check() {
      await checkLogin()
    }

    check()
  }, [])

  useEffect(() => {
    if (user !== null) {
      navigate(location?.state?.path || '/compte')
    }
  }, [])
  return (
    <Layout seo={seo}>
      <Banner
        title="Connexion"
        img={BannerYellowRoundImg}
        bannerClass="login-banner"
      />
      <Container className="connexion-page py-5">
        <Row>
          <Col>
            <Heading className="connexion-page-title" title="Me connecter" />
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <Icon name="icon-login" iconClass="login-wrapper" paths={9} />
            <p className="pt-3 text-primary">
              <strong>Connectez</strong> vous ici !
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 10, offset: 1 }}>
            <LoginForm
              handleSubmit={login}
              path={
                location.state !== undefined && location.state !== null
                  ? location.state.path
                  : null
              }
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query SignInPage($id: String!) {
    wpPage(id: { eq: $id }) {
      ...yoastMeta
    }
  }
`

SignInTemplate.propTypes = {
  login: propTypes.func.isRequired,
  checkLogin: propTypes.func.isRequired,
  user: propTypes.objectOf(propTypes.any),
  data: propTypes.objectOf(propTypes.any).isRequired,
  location: propTypes.objectOf(propTypes.any).isRequired,
}
SignInTemplate.defaultProps = { user: null }

const mapStateToProps = state => ({
  user: getUser(state),
})
const mapDispatchToProps = dispatch => ({
  login: (data, path) => dispatch(doLogin(data, path)),
  checkLogin: () => dispatch(doCheckLogin()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignInTemplate)
